<template>
  <main class="mx-auto max-w-xl flex flex-col items-center justify-center px-8 overflow-hidden h-dvh">
    <PreloaderLayer :status="this.status == 'loading'" />
    <Transition name="main">

      <div class="flex flex-col items-center justify-center w-full space-y-6">

        <div v-if="currentCard?.type == 'image'">
          <img :src="currentCard?.media" alt="" class="fa-bounce">
        </div>

        <div v-else-if="currentCard?.type == 'video'">
          <iframe
            class="w-full aspect-video"
            :src="`${currentCard?.media}&amp;controls=0`"
            title="YouTube video player"
            frameborder="0"
            allow="
              accelerometer;
              autoplay;
              clipboard-write;
              encrypted-media;
              gyroscope;
              picture-in-picture;
              web-share
            "
            allowfullscreen
          />
        </div>

        <div class="w-full space-y-3">
          <h1 class="text-xl font-medium text-center text-gdp-300">{{ currentCard?.title }}</h1>
          <p class="text-center">{{ currentCard?.description }}</p>
        </div>

        <button @click="nextCard()" :class="[
          'w-full py-3 font-semibold text-white rounded-xl bg-gdp-300 transition-all',
          'hover:scale-105',
        ]">
          {{ isFinalCard ? 'Iniciar simulacro' : 'Siguiente' }}
        </button>
      </div>

    </Transition>
  </main>
</template>

<script>
import PreloaderLayer from '@/components/layers/PreloaderLayer.vue'

import server from '@/api/index.js'

export default {
  name: 'OnboardingView',
  components: {
    PreloaderLayer
  },
  data() {
    return {
      cards: [],
      status: 'loading',
    }
  },
  computed: {
    totalCards() {
      return this.cards.length
    },
    currentCard() {
      return this.cards.find(card => card.current === true)
    },
    isFinalCard() {
      return this.currentCard === this.cards[this.totalCards - 1]
    }
  },
  methods: {
    async getOnboarding() {
      try {
        const data = await server.getOnboarding()
        data.forEach(card => {
          card.current = false
        })
        data[0].current = true
        this.cards = data
        this.status = 'success'
      } catch (error) {
        this.status = 'error'
      }
    },
    nextCard() {
      const currentCard = this.cards.find(card => card.current === true)
      const currentCardIndex = this.cards.indexOf(currentCard)
      const nextCard = this.cards[currentCardIndex + 1]
      if (nextCard) {
        currentCard.current = false
        nextCard.current = true
      }
      else {
        this.finishOnboarding()
      }
    },
    prevCard() {
      const currentCard = this.cards.find(card => card.current === true)
      const currentCardIndex = this.cards.indexOf(currentCard)
      const beforeCard = this.cards[currentCardIndex - 1]
      if (beforeCard) {
        currentCard.current = false
        beforeCard.current = true
      }
    },
    finishOnboarding() {
      this.$router.push({ name: 'login' })
    }
  },
  async mounted() {
    await this.getOnboarding()
  },
}
</script>