import { createApp } from 'vue'

// Main File
import App from './App.vue'

// Modules
import router from './router'
import { createPinia } from 'pinia'

// PWA Settings
// import './registerServiceWorker'

// Styles
import './assets/tailwind.css'
import "./assets/fontawesome/css/all.min.css";

// App instance
const app = createApp(App)

// App global modules
app.use(createPinia())
app.use(router)

// Mount App
app.mount('#app')
