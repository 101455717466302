<template>
  <preloader-layer :status="loading" />
  <main class="mx-auto max-w-xl flex flex-col items-center justify-start p-8 overflow-hidden space-y-10">
    <!-- <CircularChart /> -->

    <img src="@/assets/logo.png" alt="logo" class="w-28">

    <!-- Saludo -->
    <h1 class="text-3xl font-medium text-center">
      ¡Hola,
      <span class="text-gdp-300"> {{global.user?.name}}!</span>
    </h1>

    <!-- Iniciar Simulacro -->
    <div class="border border-gray-400 w-full rounded-2xl space-y-3 p-6">
      
      <div class="flex flex-row items-center justify-center space-x-4">
        <div class="w-12 p-2 border border-gray-400 rounded-full overflow-hidden ">
          <img src="@/assets/imgs/timer.png" alt="" class="w-full">
        </div>
        <h1 class="text-xl leading-none font-medium text-left">
          ¡Inicia tu <br>
          <span class="text-gdp-300">Simulacro</span>
        </h1>
      </div>

      <div class="flex flex-col items-start justify-center">
        <label for="speciality" class="text-gray-500 text-sm">Especialidad</label>
        <select v-model="global.setExam.speciality" name="speciality" id="speciality" class="border w-full border-gray-400 rounded-xl">
          <option v-for="(i, index) in allSpecialities" :key="index">{{ i }}</option>
        </select>
      </div>

      <!-- <div class="flex flex-col items-start justify-center">
        <label for="speciality" class="text-gray-500 text-sm">Nivel</label>
        <select v-model="global.setExam.level" name="speciality" id="speciality" class="border w-full border-gray-400 rounded-xl">
          <option v-for="(i, index) in allLevels" :key="index">{{ i }}</option>
        </select>
      </div> -->

      <!-- <div class="flex flex-col items-start justify-center">
        <label for="speciality" class="text-gray-500 text-sm">Escala</label>
        <select name="speciality" id="speciality" class="border w-full border-gray-400 rounded-xl">
          <option>A</option>
          <option>B</option>
          <option>C</option>
        </select>
      </div> -->

      <button
        @click="setExam()"
        :class="[
          'w-full py-3 font-semibold text-white rounded-xl bg-gdp-300 transition-all',
          'hover:scale-105',
        ]">
          Comenzar
      </button>

    </div>

    <!-- Consulta a GDPecito -->
    <a :href="global.isReffered ? global.wameLink : 'https://wa.me/51948978017'" class="flex flex-row w-full items-center justify-between bg-gdp-300 text-white rounded-xl overflow-hidden p-2">
      <img src="@/assets/imgs/bot.png" alt="" class="w-16">
      <h1 class="text-left">
        Quiero mejorar mi puntaje para el exámen de Nombramiento 📝
        <i class="fa-solid fa-chevron-right"></i>
      </h1>
    </a>

    <!-- Noticias -->
    <div id="swiper" class="w-full relative">
      <h2 class="mb-2">Últimas <span class="text-gdp-300">noticias</span></h2>
      <swiper
        :slides-per-view="1"
        :pagination="true"
        :space-between="25"
        :modules="modules"
        :autoplay="{
          delay: 1500,
          disableOnInteraction: true,
        }"
      >
        <swiper-slide v-for="(i, index) in global.news" :key="index" class="w-full border rounded-2xl aspect-square overflow-hidden">
          <img :src="i.image" alt="">
          <a target="_blank" :href="i.url" class="bg-white w-full absolute bottom-0 h-1/2 p-4 rounded-t-2xl">
            <span class="text-gray-400 text-sm">{{ i.date }}</span>
            <h2 class="leading-tight">
              <span class="font-bold">{{ i.heading }}</span>
              {{ i.body }}</h2>
            <span class="text-gray-400 text-sm">Fuente: {{ i.source }}</span>
          </a>
        </swiper-slide>
      </swiper>
    </div>

    <!--Links-->
    <div class="flex flex-col items-center justify-center">
      <h3 class="mb-2">Contáctanos por</h3>
      <div class="grid grid-cols-3 gap-2">
        <a target="_blank" href="https://www.facebook.com/profile.php?id=100086508537253" class="w-10 h-10 flex flex-col items-center justify-center rounded-full text-gdp-300 bg-gdp-300 bg-opacity-20">
          <i class="fa-brands fa-facebook"/>
        </a>
        <a target="_blank" href="https://www.grupodocenteperu.com/" class="w-10 h-10 flex flex-col items-center justify-center rounded-full text-gdp-300 bg-gdp-300 bg-opacity-20">
          <i class="fa-solid fa-globe"/>
        </a>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=51948978017" class="w-10 h-10 flex flex-col items-center justify-center rounded-full text-gdp-300 bg-gdp-300 bg-opacity-20">
          <i class="fa-brands fa-whatsapp"/>
        </a>
      </div>
    </div>

  </main>
</template>

<script>
import { globalData } from '@/store/index.js'

import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/bundle";
import PreloaderLayer from '@/components/layers/PreloaderLayer.vue';

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    PreloaderLayer
},
  props: {
  },
  data() {
    return {
      global: globalData(),
      modules: [
        Pagination,
        Autoplay,
      ],
      loading: true,
    }
  },
  computed: {
    allSpecialities() {
      let specialities = []
      this.global.allQuestions.forEach(question => {
        //question.speciality is an array
        question.speciality.forEach(speciality => {
          if (!specialities.includes(speciality)) {
            specialities.push(speciality)
          }
        })
      })
      return specialities
    },
    allLevels() {
      const filtered = this.global.allQuestions.filter(question => question.speciality.includes(this.global.setExam.speciality))
      let levels = []
      filtered.forEach(question => {
        //question.speciality is an array
        console.log(question.level)
        question.level.forEach(level => {
          if (!levels.includes(level)) {
            levels.push(level)
          }
        })
      })
      return levels
    },
  },
  watch: {
  },
  methods: {
    decode64(str) {
      let decoded = atob(str);
      return decoded
    },
    getRefferedParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const refferedParam = urlParams.get('r');
      if (refferedParam) {
        const decoded = this.decode64(refferedParam);
        return decoded
      } else {
        return null
      }
    },
    setReffered() {
      const global = globalData()
      if (global.isReffered) {
        return // if reffered exists, do nothing
      }
      let data = this.getRefferedParam();
      if (!data) {
        global.isReffered = false;
        return // If no reffered param, do nothing
      }
      global.r_code = data.split('_')[0];
      global.r_number = data.split('_')[1];
      global.isReffered = true;
    },
    checkIfLoggin() {
      const global = globalData()
      console.log(global.isLogged)
      if (!global.isLogged) {
        this.setReffered()
        this.$router.push({ name: 'onboarding' })
      }
    },
    setExam() {
      // const filteredBySpeciality = this.global.allQuestions.filter(question => question.speciality.includes(this.global.setExam.speciality))
      // const filteredByLevel = filteredBySpeciality.filter(question => question.level.includes(this.global.setExam.level))
      const filteredByLevel = this.global.allQuestions.filter(question => question.speciality.includes(this.global.setExam.speciality))
      let questions = []

      if (filteredByLevel.length < 75) {
        questions = filteredByLevel
      } else {
        for (let i = 0; i < 75; i++) {
          questions.push(filteredByLevel[i])
        }
      }

      this.global.questions = questions
      this.$router.push({ name: 'exam' })
    }
  },
  beforeCreate() {
  },
  created() {
  },
  beforeMount() {
  },
  async mounted() {
    try {
      this.checkIfLoggin()
      await this.global.getQuestions()
      await this.global.getAllNews()
      this.loading = false
      if (this.global.forceSelection && this.global.user._id) {
        this.global.setExam.speciality = this.global.forceSelection
        this.setExam()
      }
    } catch (error) {
      console.log(error)
    }
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeUnmount() {
  },
  unmounted() {
  },
}
</script>

<style scoped>
#swiper  {
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
</style>