<template>
  <div v-if="status" class="preloader-overlay">
    <div class="preloader-spinner"></div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Puedes ajustar el color y la opacidad */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
