import { defineStore } from "pinia";
import server from "@/api/index.js";

import { useStorage } from '@vueuse/core'

export const globalData = defineStore("globalData", {
	state: () => ({
		forceSelection: null,

		user: useStorage('user',{
			_id: null,
			name: null,
			lastname: null,
			email: null,
			phone: null,
		}),
		setExam: {
			speciality: "",
			level: "",
			scale: "",
		},
		questions: useStorage('questions', []),
		allQuestions: [],
		news: [],

		isReffered: useStorage('isReffered', false),
		r_code: useStorage('r_code', ''),
		r_number: useStorage('r_number', ''),
	}),
	getters: {
		isLogged: (state) => {
			return state.user._id !== null && state.user._id !== '';
		},
		totalQuestions: (state) => {
			return state.questions.length;
		},
		wameLink: (state) => {
			return `https://wa.me/51${state.r_number}`;
		},
	},
	actions: {
		async login(name, lastname, email, phone, referred) {
			this.user = await server.login(name, lastname, email, phone, referred);
		},
		async getQuestions(speciality, level) {
			this.allQuestions = await server.getQuest(speciality, level);
		},
		async getAllNews() {
			this.news = await server.getNews();
		},
	},
});
