<template>
  <router-view/>
</template>



<script>
import { globalData } from './store/index.js';

export default {
  data() {
    return {
      global: globalData(),
    }
  },
  methods: {
    decode64(str) {
      let decoded = atob(str);
      return decoded
    },
    getRefferedParam() {
      const urlParams = new URLSearchParams(window.location.search);
      const refferedParam = urlParams.get('r');
      if (refferedParam) {
        const decoded = this.decode64(refferedParam);
        return decoded
      } else {
        return null
      }
    },
    setReffered() {
      const global = globalData()
      if (global.isReffered) {
        return // if reffered exists, do nothing
      }
      let data = this.getRefferedParam();
      if (!data) {
        global.isReffered = false;
        return // If no reffered param, do nothing
      }
      global.r_code = data.split('_')[0];
      global.r_number = data.split('_')[1];
      global.isReffered = true;
    }
  },
  mounted() {
    const global = globalData()
    this.setReffered()
    // Get "e" query from URL
    const urlParams = new URLSearchParams(window.location.search);
    const examParam = urlParams.get('e');
    global.forceSelection = examParam
  }
}

</script>





<style>
body {
  background: rgba(255, 255, 255, 0.5);
  background: url('https://i.imgur.com/d3nCsMj.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
body > main {
  background: rgb(255, 255, 255);
}
</style>