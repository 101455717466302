import axios from 'axios';

const server = axios.create({
  baseURL: 'https://t9tl4qq6q9.execute-api.us-east-1.amazonaws.com/production/simulacros/v1/',
  // baseURL: 'http://localhost:3001/simulacros/v1/',
})
async function sleep(s) {
  return new Promise((resolve) => {
    setTimeout(resolve, s * 1000)
  })
}
// 60
async function getOnboarding() {
  await sleep(1)
  return [
    {
      type: 'image',
      media: 'https://backend.grupodocenteperu.com/v1/storage/buckets/65ac27e4b45b9c8189fa/files/65ac2888c0f80b9064f5/view?project=65607e3d5bb3b4c324ad&mode=admin',
      title: 'Liderando la innovación académica',
      description: 'Grupo Docente Perú forma profesionales exitosos en docencia con un enfoque integral y recursos de calidad',
    },
    {
      type: 'image',
      media: 'https://backend.grupodocenteperu.com/v1/storage/buckets/65ac27e4b45b9c8189fa/files/65ac2884395dcc8ea5e1/view?project=65607e3d5bb3b4c324ad&mode=admin',
      title: 'Plataforma de Simulacros',
      description: 'Diseñada para una preparación efectiva con preguntas variadas y análisis detallado del desempeño individual.',
    },
    {
      type: 'video',
      media: 'https://www.youtube.com/embed/UCzpCY3Lvfo?si=-OW1lCRf5trb87bG',
      title: 'Plataforma de Simulacros',
      description: 'Diseñada para una preparación efectiva con preguntas variadas y análisis detallado del desempeño individual.',
    },
  ]
}

async function login(name, lastname, email, phone, referred) {
  try {
    let { data } = await server.post('/users', {
      name: name,
      lastname: lastname,
      email: email,
      phone: phone,
      referred: referred
    })
    console.log(data)
    return data
  } catch (error) {
    console.log(error)
  }

}

async function getQuest(speciality, level) {
  console.log({speciality, level})
  try {
    let { data } = await server.get('/questions')
    console.log(data)
    return data
  } catch (error) {
    console.log(error)
  }
}

async function saveExam(id, exam) {
  try {
    let { data } = await server.post(`/users/addExam/${id}`, exam)
    return data
  } catch (error) {
    console.log(error)
  }
}

async function getNews() {
  try {
    let {data} = await server.get('/news')
    return data
  } catch (error) {
    console.log(error)
  }

}

export default {
  getOnboarding,
  login,
  getQuest,
  saveExam,
  getNews
}